import React from "react";
import Layout from "../../components/layout";
import ServicesHero from "../../components/services/hero";
import Review from "../../components/review";
import PhotoLeft from "../../components/services/photo-left";
import PhotoRight from "../../components/services/photo-right";
import Montage from "../../components/services/montage";
import CtaBanner from "../../components/cta-banner";
import Faq from "../../components/services/faq";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";

const PersonalBrandingPage = () => {
	const data = useStaticQuery(graphql`
		query {
			logoImg: file(relativePath: { eq: "logo.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
			heroImg: file(relativePath: { eq: "services/headshots.jpg" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			expertImg: file(
				relativePath: { eq: "services/personal-branding/expert.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			homeImg: file(
				relativePath: { eq: "services/personal-branding/home.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			minaImg: file(
				relativePath: { eq: "services/personal-branding/mina.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			sonnyImg: file(
				relativePath: { eq: "services/personal-branding/sonny.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			headshot2Img: file(
				relativePath: { eq: "services/personal-branding/headshot2.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			headshot3Img: file(
				relativePath: { eq: "services/personal-branding/headshot3.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			headshot1Img: file(
				relativePath: { eq: "services/personal-branding/headshot1.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			vinceImg: file(
				relativePath: { eq: "services/personal-branding/vince.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			mediaImg: file(
				relativePath: { eq: "services/personal-branding/media.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			site {
				siteMetadata {
					siteUrl
					company
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const pageUrl = "/services/personal-branding";
	const companyName = data.site.siteMetadata.company;
	const schemaImg = data.heroImg.publicURL;
	const logoImg = data.logoImg.publicURL;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: `${siteUrl}`,
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Personal Branding Photographer",
				item: `${siteUrl}/services/personal-branding`,
			},
		],
	};
	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `${siteUrl}${pageUrl}#personal-branding-photographer`,
		image: `${siteUrl}${schemaImg}`,
		mpn: `${siteUrl}`,
		sku: `${siteUrl}${pageUrl}`,
		description:
			"Headshot and Personal Branding Photography is an essential part of branding your business. I will bring my mobile studio to you to photograph your team. Click now to see my work.",
		logo: `${siteUrl}${logoImg}`,
		name: `${companyName}`,
		url: `${siteUrl}`,
		brand: {
			"@type": "Organization",
			logo: `${siteUrl}${logoImg}`,
			image: `${siteUrl}${schemaImg}`,
			name: `${companyName}`,
			url: `${siteUrl}`,
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: "24",
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: `${siteUrl}/reviews`,
			datePublished: "2021-04-10T17:58:29+00:00",
			reviewBody:
				"Sarah is more than a great photographer, her professionalism and business sense makes her the best choice for any business. Sarah makes sure she understands what her clients want to achieve before she even gets her camera out! When she does her photographs are amazing.",
			author: {
				"@type": "Person",
				name: "Jo Bremner",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: `${companyName}`,
				sameAs: `${siteUrl}`,
			},
		},
	};

	const faqs = [
		{
			question:
				"What is the difference between personal branding and a headshot?",
			answer:
				"A common mistake made is often headshots being seen as a personal branding shoot. Of course, headshots are a form of personal branding and should be included in a shoot but personal branding goes a lot further than that. Headshots are you, normally face on to the camera, in one outfit and in front of a backdrop of sorts in a single location. Normally for the about us page on a website, a good quality headshot is essential for showing your personality and engaging with clients. Personal Branding photography tells the story of you and your business to others. How? I create a variety of images for you to use in your brand and marketing communications over a period of time, for example a year. This could be natural shots of you in a coffee shop working from your laptop, engaging with clients in a meeting, or simply with your dog. Then you have the more posed portraits, relaxed compared to a headshot, so laughing, smiling, sitting down, etc. You should change into different outfits and use a variety of locations. It could be you’re a fitness trainer, so shots of you working out, cooking a healthy meal, or even helping a client out with their fitness regime, would all count as your personal brand, what you stand for and what you believe in.",
		},
		{
			question:
				"I have 20 members of staff I need headshots for, but I don’t want them wandering off for hours, can you come to us?",
			answer:
				"Yes!  This is what I do. I don’t tend to work on singular headshots. I work with large companies who need multiple members of staff photographed. I bring my mobile studio and set up in an area on site. I have a running order, sorted before and I work through that. 20 Staff normally takes a couple of hours maximum. ",
		},
		{
			question: "How many shots per member of staff do we get?",
			answer:
				"I take 3 different images per member and you get to choose one per member of staff. These can be done in colour or black and white, just ask when we speak.",
		},
		{
			question:
				"Some of my staff members are very nervous. Can you work with that?",
			answer:
				"I work with a lot of nervous people, in fact most people hate having their photo taken. (Me included!) so I make it very simple and straight forward. I give direction and three simple poses. I run through this with everyone before I press any camera button. I give them tips to relax and until they are ready, I won’t pick up the camera. I always try and set up in a different room so there are no lookers on and take my time and make sure everyone is relaxed and happy.",
		},
	];

	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>
			<GatsbySeo
				title="Headshot and Personal Branding Photographer | Sarah Bennett Photography"
				description="Headshot and Personal Branding Photography is an essential part of branding your business. I will bring my mobile studio to you to photograph your team. Click now to see my work."
				language="en"
				canonical="https://www.sarah-bennett.com/services/personal-branding"
				openGraph={{
					url: "https://www.sarah-bennett.com/services/personal-branding",
					title:
						"Professional Headshot Photographer | Personal Branding Photographer",
					description:
						"Headshot Photography is an essential part of branding your business. I will bring my mobile studio to you to photograph your team. Click now to see my work.",
					images: [
						{
							url: `${data.heroImg.publicURL}`,
							width: 800,
							height: 600,
							alt: "Personal Branding and Headshot Photographer in East and West Sussex",
						},
					],
				}}
			/>
			<ServicesHero
				img={data.heroImg.childImageSharp.gatsbyImageData}
				headline="Headshot and Personal Branding Photographer"
				showBtn={true}
			/>
			<PhotoLeft
				img={data.expertImg.childImageSharp.gatsbyImageData}
				heading="Present your self online"
				text="Headshot photography is essential these days for building a personal brand and presenting yourself to the online world via your website and social media profiles. I offer all kinds of personal branding photography, whether you’re a business owner and have a team of staff, or you are a thought leader, solopreneur or just want a personal branding session for social media profile photography on LinkedIn, Facebook or Instagram or blog posts."
			/>
			<PhotoRight
				img={data.homeImg.childImageSharp.gatsbyImageData}
				heading="Build Credibility"
				text="Having up-to-date personal branding photography is so important for credibility among your business contacts. A strong personal brand will ensure you don’t end up as that person that someone meets face-to-face for the first time, only to find out you no longer look like the images used on your social media platforms or website."
			/>
			<Review
				author="Mia Cherry"
				quote="I needed professional photos for my website and Sarah was my first and only choice. I've seen her work in the past and she has taken photos of my family before so I knew I'd be in great hands. She made me feel at ease and relaxed. The photos are amazing! I highly recommend Sarah if you need business photos!"
				auth="Mia Cherry"
				company="People Management Training and Coaching"
			/>
			<Montage
				img1={data.minaImg.childImageSharp.gatsbyImageData}
				img2={data.sonnyImg.childImageSharp.gatsbyImageData}
				img3={data.headshot2Img.childImageSharp.gatsbyImageData}
				img4={data.headshot3Img.childImageSharp.gatsbyImageData}
				img5={data.headshot1Img.childImageSharp.gatsbyImageData}
			/>
			<CtaBanner
				headline="Refresh your personal brand with bespoke photos that reflect where your business is now - and where you want it to go!"
				btnText="Let's Talk"
				url="/contact-us"
			/>
			<PhotoLeft
				img={data.vinceImg.childImageSharp.gatsbyImageData}
				heading="We will guide you along the way"
				text="With a personal branding shoot, I advise clients, from the outfits they wear to the location and props that will be used in creating a personal brand for them. As much as possible I use natural light during the photo shoot always keeping in mind branding images that you can use to build your brand. We take headshot photos with plain or natural backgrounds and move to different locations that reflect you and your brand."
			/>
			<PhotoRight
				img={data.mediaImg.childImageSharp.gatsbyImageData}
				heading="How your photos are delivered"
				text="I take actors headshots for portfolios along with authors headshots for book jackets. My work is delivered as digital images so you can immediately start using them."
				cta="Ready to get started?"
				ctaUrl="/contact-us"
			/>
			<Faq faqs={faqs} />
			<CtaBanner
				headline="Book an appointment to discuss your personal branding photography"
				btnText="Book an appointment"
				url="/contact-us"
			/>
		</Layout>
	);
};

export default PersonalBrandingPage;
